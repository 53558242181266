import { useMemo } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import type { LoyaltySignupWidgetAdapter } from '@sevenrooms/core/api'
import { z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useMultiSelectTagOptionForm, useBirthdayDayInputForm, useBirthdayMonthInputForm } from '@sevenrooms/core/ui-kit/form'
import { loyaltySignupWidgetMessages } from '../loyaltySignupWidgetMessages'

type useLoyaltySignupFormProps = Omit<
  ReturnType<typeof LoyaltySignupWidgetAdapter.settingsToClient>['formSettings'],
  'description' | 'header' | 'showDietaryRestrictions'
>

export function useLoyaltySignupForm({
  groupOptInDefault,
  requireBirthday,
  requirePhoneNumber,
  requirePostalCode,
  requireSalutation,
  showGroupOptIn,
  showRecaptcha,
}: useLoyaltySignupFormProps) {
  const { formatMessage } = useLocales()
  const birthdayDay = useBirthdayDayInputForm(!requireBirthday)
  const birthdayMonth = useBirthdayMonthInputForm(!requireBirthday)
  const multiSelectTagOptionForm = useMultiSelectTagOptionForm()

  return useMemo(() => {
    const form = z
      .object({
        firstName: z
          .string()
          .min(1, { message: formatMessage(loyaltySignupWidgetMessages.resWidgetErrorsFieldRequired) })
          .default(''),
        lastName: z
          .string()
          .min(1, { message: formatMessage(loyaltySignupWidgetMessages.resWidgetErrorsFieldRequired) })
          .default(''),
        email: z.string().email(formatMessage(loyaltySignupWidgetMessages.resWidgetErrorsFieldInvalid)).default(''),
        phone: z.string().nullable().default(null),
        phoneLocale: z.string().nullable().default(null),
        birthdayDay,
        birthdayMonth,
        dietaryRestrictions: multiSelectTagOptionForm,
        groupOptIn: showGroupOptIn ? z.boolean().default(groupOptInDefault) : z.boolean().optional(),
        postalCode: requirePostalCode
          ? z
              .string()
              .min(1, { message: formatMessage(loyaltySignupWidgetMessages.resWidgetErrorsFieldRequired) })
              .default('')
          : z.string().nullable().default(null),
        recaptcha: showRecaptcha
          ? z
              .string()
              .min(1, { message: formatMessage(loyaltySignupWidgetMessages.resWidgetErrorsFieldRequired) })
              .default('')
          : z.string().nullable().default(null),
        salutation: requireSalutation
          ? z
              .string()
              .min(1, { message: formatMessage(loyaltySignupWidgetMessages.resWidgetErrorsFieldRequired) })
              .default('')
          : z.string().default(''),
      })
      .superRefine(({ phone, phoneLocale }, ctx) => {
        if (requirePhoneNumber && !phone) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: formatMessage(loyaltySignupWidgetMessages.resWidgetErrorsFieldRequired),
            path: ['phone'],
          })
        }
        if (!!phone && !_isValidPhoneNumber(phone, phoneLocale)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: formatMessage(loyaltySignupWidgetMessages.resWidgetErrorsFieldInvalid),
            path: ['phone'],
          })
        }
      })
    return form
  }, [
    formatMessage,
    birthdayDay,
    birthdayMonth,
    multiSelectTagOptionForm,
    requirePhoneNumber,
    requirePostalCode,
    requireSalutation,
    showRecaptcha,
    groupOptInDefault,
    showGroupOptIn,
  ])
}

const _isValidPhoneNumber = (phoneNumber: string | null, phoneNumberLocale: string | null) => {
  if (!phoneNumber || !phoneNumberLocale) {
    return false
  }
  return isValidPhoneNumber(phoneNumber)
}
