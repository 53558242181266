import { defineMessages } from '@sevenrooms/core/locales'

export const loyaltySignupWidgetMessages = defineMessages({
  resWidgetCheckoutSubmitButtonLabel: {
    id: 'resWidgetCheckoutSubmitButtonLabel',
    defaultMessage: 'Submit',
  },
  resWidgetBirthdayLabel: {
    id: 'resWidgetBirthdayLabel',
    defaultMessage: 'Birthday',
  },
  resWidgetEmailLabel: {
    id: 'resWidgetEmailLabel',
    defaultMessage: 'Email',
  },
  resWidgetFirstNameLabel: {
    id: 'resWidgetFirstNameLabel',
    defaultMessage: 'First Name',
  },
  resWidgetLastNameLabel: {
    id: 'resWidgetLastNameLabel',
    defaultMessage: 'Last Name',
  },
  resWidgetPhoneNumberLabel: {
    id: 'resWidgetPhoneNumberLabel',
    defaultMessage: 'Phone Number',
  },
  resWidgetPostalCodeLabel: {
    id: 'resWidgetPostalCodeLabel',
    defaultMessage: 'Postal Code',
  },
  resWidgetDietaryRestrictionsLabel: {
    id: 'resWidgetDietaryRestrictionsLabel',
    defaultMessage: 'Dietary Restrictions',
  },
  resWidgetSalutation: {
    id: 'resWidgetSalutation',
    defaultMessage: 'Title/Salutation',
  },
  resWidgetErrorsFieldRequired: {
    id: 'resWidgetErrorsFieldRequired',
    defaultMessage: 'Required',
  },
  resWidgetErrorsFieldInvalid: {
    id: 'resWidgetErrorsFieldInvalid',
    defaultMessage: 'Invalid',
  },
  resConfirmPageDietaryRestrictQuestion: {
    id: 'resConfirmPageDietaryRestrictQuestion',
    defaultMessage: 'Do you have any dietary restrictions?',
  },
  policyVenueGroupMarketing: {
    id: 'policyVenueGroupMarketing',
    defaultMessage:
      'From time to time, we’ll contact you via email to keep you in the loop about what’s happening at all our locations. By clicking this, you will be resubscribed to any of our locations from which you previously unsubscribed.',
  },
  feedbackPageVenueGroupMarketingLabel: {
    id: 'feedback_page_venue_group_marketing_label',
    defaultMessage: 'Receive news and offers from all our locations',
  },
  resConfirmPageError: {
    id: 'resConfirmPageError',
    defaultMessage: 'There seems to be an error',
  },
})
