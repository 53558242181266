/* eslint-disable camelcase */
import {
  LoyaltySignupWidgetAdapter,
  type LoyaltySignupWidgetLanguageStrings,
  type LoyaltySignupWidgetSettings,
  type ReservationWidget,
  ReservationWidgetAdapter,
  type SubscriptionWidgetApi,
  SubscriptionWidgetAdapter,
} from '@sevenrooms/core/api'

declare const window: {
  PRELOADED: {
    widget_settings: {
      loyalty_signup_widget: LoyaltySignupWidgetSettings
      reservation_widget: ReservationWidget.Settings
    }
    recaptcha_site_key: string
    salutation_options: string[]
    base_venue: SubscriptionWidgetApi.Venue
    selected_language_strings: LoyaltySignupWidgetLanguageStrings
    loyalty_program_id: string
  }
}

export const preloadedState = {
  loyaltySignupWidgetSettings: LoyaltySignupWidgetAdapter.settingsToClient(window.PRELOADED.widget_settings.loyalty_signup_widget),
  reservationWidgetSettings: ReservationWidgetAdapter.settingsToClient(window.PRELOADED.widget_settings.reservation_widget),
  selectedLanguageStrings: LoyaltySignupWidgetAdapter.stringsToClient(window.PRELOADED.selected_language_strings),
  salutationOptions: window.PRELOADED.salutation_options,
  recaptchaSiteKey: window.PRELOADED.recaptcha_site_key,
  venueInfo: SubscriptionWidgetAdapter.venueToClient(window.PRELOADED.base_venue),
  loyaltyProgramId: window.PRELOADED.loyalty_program_id,
}

export type PreloadedState = typeof preloadedState
