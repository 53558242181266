import { combineReducers } from 'redux'
import { loyaltySignupApi } from '@sevenrooms/core/api'
import type { PreloadedState } from '../preloadedState'

export interface RootState extends PreloadedState {
  loyaltySignupApi: ReturnType<typeof loyaltySignupApi.reducer>
}

export const rootReducer = combineReducers({
  venueInfo: (state = {}) => state,
  loyaltySignupWidgetSettings: (state = {}) => state,
  reservationWidgetSettings: (state = {}) => state,
  selectedLanguageStrings: (state = {}) => state,
  recaptchaSiteKey: (state = {}) => state,
  salutationOptions: (state = {}) => state,
  loyaltyProgramId: (state = {}) => state,
  [loyaltySignupApi.reducerPath]: loyaltySignupApi.reducer,
})
