import { useEffect, useMemo } from 'react'
import { loyaltySignupApi } from '@sevenrooms/core/api'
import { CurrencyLocale, Locale } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, Flex, Loader, Root, VStack, CardSection, LogoFooter, TopNav } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useStoreSelector } from '../store'
import { SignupComponent } from './SignupComponent'

function SignupSuccess() {
  const { emailSubscriptionConfirmationHeader } = useStoreSelector(state => state.selectedLanguageStrings)
  const isMobile = useMaxWidthBreakpoint('s')

  return (
    <VStack display="flow" width="496px" spacing="m">
      <Box m="auto" width="fit-content">
        <CardSection
          width={isMobile ? '100%' : '496px'}
          borderColor="borders"
          flexDirection="row"
          alignItems="center"
          spacing="s"
          p="lm"
          top="xl"
        >
          <Icon color="success" size="lg" name="GX-checkbox-marked-circle" />
          <Text wordBreak="break-word" fontWeight={600} fontSize="xxl">
            {emailSubscriptionConfirmationHeader}
          </Text>
        </CardSection>
      </Box>
    </VStack>
  )
}

export function WidgetComponent() {
  const { matchQuery } = useNavigation()
  const { lang = 'en' } = matchQuery(routes.loyaltySignup) ?? {}
  const { venueInfo, selectedLanguageStrings, reservationWidgetSettings } = useStoreSelector(state => state)
  const urlLanguageLocale = Locale.getLocale(lang)

  useEffect(() => {
    CurrencyLocale.setLocale(venueInfo.locale)
  }, [venueInfo.locale])

  const themeOverride = useMemo(
    () => ({
      colors: {
        brandColor: reservationWidgetSettings.colorButton,
        venueColor: reservationWidgetSettings.colorPrimary,
      },
    }),
    [reservationWidgetSettings.colorButton, reservationWidgetSettings.colorPrimary]
  )

  const messages = useMemo(
    () => ({
      ...selectedLanguageStrings,
    }),
    [selectedLanguageStrings]
  )

  return (
    <Root theme="gx" themeOverride={themeOverride} locale={urlLanguageLocale} messages={messages}>
      <LoyaltySignupWidget />
    </Root>
  )
}

function LoyaltySignupWidget() {
  const { matchQuery } = useNavigation()
  const { embed } = matchQuery(routes.loyaltySignup) ?? {}
  const { venueInfo } = useStoreSelector(state => state)
  const [signupMutation, { isSuccess, isLoading }] = loyaltySignupApi.useSignupMutation()

  const isMobile = useMaxWidthBreakpoint('s')
  const padding = isMobile ? undefined : 'lm'

  const venuePath = `/explore/${venueInfo.urlKey}/reservations/search`

  return (
    <VStack justifyContent="space-between" minHeight="100vh" backgroundColor="secondaryBackground">
      <VStack width="100%" flex="1">
        {!embed && <TopNav logoLabel={venueInfo.name} baseUrl={venuePath} logo={venueInfo.largeLogoUrl} />}
        <Flex
          maxWidth="1440px"
          width="100%"
          alignSelf="center"
          justifyContent="center"
          pl={padding}
          pr={padding}
          data-test="widget-content"
          flex="1"
          backgroundColor="primaryBackground"
        >
          {!isSuccess && !isLoading && <SignupComponent signupMutation={signupMutation} />}
          {isSuccess && <SignupSuccess />}
          {isLoading && (
            <Flex minHeight="274px" justifyContent="center" alignItems="center" data-test="loading-box">
              <Loader />
            </Flex>
          )}
        </Flex>
      </VStack>
      <LogoFooter />
    </VStack>
  )
}
