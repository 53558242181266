import { useCallback, useMemo, useState } from 'react'
import { parsePhoneNumber } from 'react-phone-number-input'
import type { loyaltySignupApi, LoyaltySignupFields } from '@sevenrooms/core/api'
import type { CountryCode } from '@sevenrooms/core/domain'
import { useForm, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { DateOnly } from '@sevenrooms/core/timepiece'
import {
  Button,
  Checkbox,
  FormBirthday,
  FormInput,
  FormPhoneNumberInput,
  FormReCaptcha,
  FormSelect,
  Label,
  MultiSelectTag,
} from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Box, HStack, notify, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useLoyaltySignupForm } from '../forms/LoyaltySignup.zod'
import { loyaltySignupWidgetMessages } from '../loyaltySignupWidgetMessages'
import { useLoyaltySignupWidgetStore } from '../store'

interface DietaryRestriction {
  id: string
  label: string
}
interface FormStateProps
  extends Omit<LoyaltySignupFields, 'venueId' | 'birthdayDay' | 'birthdayMonth' | 'dietaryRestrictions' | 'loyaltyProgramId'> {
  birthdayDay: string | null
  birthdayMonth: string | null
  dietaryRestrictions: DietaryRestriction[]
}

interface SignupComponentProps {
  signupMutation: ReturnType<typeof loyaltySignupApi.useSignupMutation>[0]
}

const labelTitle = (label: string, isRequired: boolean | null) => {
  if (!label) {
    return ''
  }
  switch (isRequired) {
    case true:
      return `${label}*`
    default:
      return label
  }
}

export function SignupComponent({ signupMutation }: SignupComponentProps) {
  const { formatMessage } = useLocales()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    venueInfo,
    loyaltySignupWidgetSettings: {
      formSettings: {
        description,
        groupOptInDefault,
        header,
        requireBirthday,
        showDietaryRestrictions,
        requirePhoneNumber,
        requirePostalCode,
        requireSalutation,
        showGroupOptIn,
        showRecaptcha,
      },
    },
    recaptchaSiteKey,
    salutationOptions,
    loyaltyProgramId,
  } = useLoyaltySignupWidgetStore()
  const params = new URLSearchParams(window.location.search)
  const { field, handleSubmit } = useForm(
    useLoyaltySignupForm({
      groupOptInDefault,
      requireBirthday,
      requirePhoneNumber,
      requirePostalCode,
      requireSalutation,
      showGroupOptIn,
      showRecaptcha,
    }),
    {
      defaultValues: {
        dietaryRestrictions: [],
        phoneLocale: venueInfo.countryCode,
        email: params.get('email') ?? undefined,
        groupOptIn: groupOptInDefault,
      },
    }
  )

  const dietaryRestrictionOptions = useMemo(
    () => venueInfo.dietaryRestrictions.map(i => ({ id: i.id, label: i.label, categoryId: 'dietaryRestrictions' })),
    [venueInfo.dietaryRestrictions]
  )

  const titleOptions = useMemo(
    () =>
      salutationOptions.map(i => ({
        id: i,
        label: i,
      })),
    [salutationOptions]
  )

  const recaptcha = useWatch(field.prop('recaptcha'))
  const handlePhoneLocaleChange = (newCountry?: CountryCode) => {
    field.prop('phoneLocale').set(newCountry ?? '')
  }

  const onClick = useCallback(
    async (formState: FormStateProps) => {
      setIsSubmitting(true)
      try {
        const [mm, dd] = [formState.birthdayMonth, formState.birthdayDay]
        const birthday = mm && dd ? DateOnly.from(`1968-${mm}-${dd}`).toJsDate() : undefined
        const dietaryRestrictions = formState.dietaryRestrictions?.map((i: { id: string }) => i.id)
        const phoneLocale = formState.phoneLocale?.toUpperCase()
        const phone = (formState.phone && parsePhoneNumber(formState.phone)?.nationalNumber) || ''
        await signupMutation({
          ...formState,
          venueId: venueInfo.id,
          birthday,
          dietaryRestrictions,
          phoneLocale,
          phone,
          loyaltyProgramId,
        })
          .unwrap()
          .catch(err => notify({ content: err, type: 'error' }))
      } catch (error) {
        if (error instanceof Error) {
          notify({ content: error, type: 'error' })
        } else {
          notify({ content: formatMessage(loyaltySignupWidgetMessages.resConfirmPageError), type: 'error' })
        }
      }
      setIsSubmitting(false)
    },
    [formatMessage, signupMutation, venueInfo.id, loyaltyProgramId]
  )

  const { matchQuery } = useNavigation()
  const { embed } = matchQuery(routes.loyaltySignup) ?? {}

  const isMobile = useMaxWidthBreakpoint('s')
  const margin = isMobile ? 'm' : 'xxl'
  const displayTooltip = !embed && !isMobile

  const containerStyling = embed
    ? ({ pl: 'lm', pr: 'lm', pb: 'lm' } as const)
    : ({
        p: 'lm',
        m: margin,
        borderRadius: 's',
        borderColor: 'borders',
      } as const)

  return (
    <Box width="496px" height="fit-content" {...containerStyling}>
      <VStack spacing="m">
        {!embed && (
          <HStack>
            <Text color="primaryFont" fontSize="xxl" fontWeight={600}>
              {header}
            </Text>
          </HStack>
        )}
        {!embed && description && (
          <HStack>
            <Text color="secondaryFont" fontSize="m">
              {description}
            </Text>
          </HStack>
        )}
        {requireSalutation !== null && (
          <Label secondary={labelTitle(formatMessage(loyaltySignupWidgetMessages.resWidgetSalutation), requireSalutation)} primary="">
            <FormSelect
              field={field.prop('salutation')}
              options={titleOptions}
              id="title"
              placeholder={formatMessage(loyaltySignupWidgetMessages.resWidgetSalutation)}
              disabled={isSubmitting}
              data-test="salutation"
            />
          </Label>
        )}
        <Label secondary={labelTitle(formatMessage(loyaltySignupWidgetMessages.resWidgetFirstNameLabel), true)} primary="">
          <FormInput field={field.prop('firstName')} inputMode="text" disabled={isSubmitting} data-test="first-name" />
        </Label>
        <Label secondary={labelTitle(formatMessage(loyaltySignupWidgetMessages.resWidgetLastNameLabel), true)} primary="">
          <FormInput field={field.prop('lastName')} inputMode="text" disabled={isSubmitting} data-test="last-name" />
        </Label>
        <Label secondary={labelTitle(formatMessage(loyaltySignupWidgetMessages.resWidgetEmailLabel), true)} primary="">
          <FormInput field={field.prop('email')} inputMode="email" disabled={isSubmitting} data-test="email" />
        </Label>
        {requirePhoneNumber !== null && (
          <Label
            secondary={labelTitle(formatMessage(loyaltySignupWidgetMessages.resWidgetPhoneNumberLabel), requirePhoneNumber)}
            primary=""
          >
            <FormPhoneNumberInput
              field={field.prop('phone')}
              defaultCountryCode={venueInfo.countryCode as CountryCode}
              onCountryChange={handlePhoneLocaleChange}
              hideCountryFlags={venueInfo.hideCountryFlags}
              disabled={isSubmitting}
              data-test="phone"
            />
          </Label>
        )}
        {requireBirthday !== null && (
          <Label secondary={labelTitle(formatMessage(loyaltySignupWidgetMessages.resWidgetBirthdayLabel), requireBirthday)} primary="">
            <FormBirthday
              dayField={field.prop('birthdayDay')}
              monthField={field.prop('birthdayMonth')}
              locale={venueInfo.locale}
              disabled={isSubmitting}
              fullWidth
              data-test="birthday"
            />
          </Label>
        )}
        {requirePostalCode !== null && (
          <Label secondary={labelTitle(formatMessage(loyaltySignupWidgetMessages.resWidgetPostalCodeLabel), requirePostalCode)} primary="">
            <FormInput field={field.prop('postalCode')} disabled={isSubmitting} data-test="postal-code" />
          </Label>
        )}
        {showDietaryRestrictions !== null && (
          <Label secondary={labelTitle(formatMessage(loyaltySignupWidgetMessages.resWidgetDietaryRestrictionsLabel), false)} primary="">
            <MultiSelectTag
              field={field.prop('dietaryRestrictions')}
              options={dietaryRestrictionOptions}
              id="dietary-restrictions"
              placeholder={formatMessage(loyaltySignupWidgetMessages.resConfirmPageDietaryRestrictQuestion)}
              categories={[{ id: 'dietaryRestrictions', name: '', color: '#3A4049' }]}
              disabled={isSubmitting}
            />
          </Label>
        )}
        {showGroupOptIn && !displayTooltip && (
          <Text textStyle="body1" lineHeight="l" fontSize="s">
            {formatMessage(loyaltySignupWidgetMessages.policyVenueGroupMarketing)}
          </Text>
        )}
        {showGroupOptIn && (
          <Checkbox
            field={field.prop('groupOptIn')}
            info={displayTooltip ? <>{formatMessage(loyaltySignupWidgetMessages.policyVenueGroupMarketing)}</> : undefined}
            disabled={isSubmitting}
            data-test="group-opt-in"
            hideErrorMessage
          >
            <Text textStyle="body1" lineHeight="l">
              {formatMessage(loyaltySignupWidgetMessages.feedbackPageVenueGroupMarketingLabel)}
            </Text>
          </Checkbox>
        )}
        {showRecaptcha && recaptchaSiteKey && <FormReCaptcha field={field.prop('recaptcha')} siteKey={recaptchaSiteKey} />}
        <HStack pt="s">
          <Button
            variant="primary"
            fullWidth
            type="submit"
            onClick={handleSubmit(onClick)}
            disabled={isSubmitting || (showRecaptcha && !recaptcha)}
            data-test="submit-button"
          >
            {formatMessage(loyaltySignupWidgetMessages.resWidgetCheckoutSubmitButtonLabel)}
          </Button>
        </HStack>
      </VStack>
    </Box>
  )
}
